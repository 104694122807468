<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? data.name || "Информация о товаре" : "Новая запись товара" }}
      </template>

      <a-form-model v-if="editMode" v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
      <div v-else>
        <a-form-view v-model="data" :model="model" :config="{ dense: true }" />
        <a-view-images :value="data.photos" />
      </div>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="canEdit && editMode" @click="submit()" />
        <a-btn-delete v-if="id && canEdit && editMode" @click="removeDialogShow = true" />
        <a-btn-edit v-show="!editMode && canEdit" @click="editMode = true" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    <su-Data
      ref="suEdit"
      v-model="suEditShow"
      :api="api"
      :id="id"
      :activate="value"
      :dataEdit="data"
      @show="suEditShow = true"
      v-if="getAccess('suData')"
    />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess],
  props: {
    value: Boolean,
    id: Number,
  },
  data() {
    return {
      model: this.$store.getters["config/get"].models.storageGoods.form,
      api: "/accounting/dir/good",
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить этот товар?",
      idEdit: 0,
      suEditShow: false,
      editMode: false,
    };
  },
  computed: {
    canEdit() {
      return this.getAccess("storageGoods.edit");
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.editMode = false;
        this.updateData(this.id);
        if (!this.id) {
          this.editMode = true;
          //  this.data.status = 0;
        }
      }
    },
  },
  created() {
    if (this.value) {
      this.editMode = false;
      this.updateData(this.id);
      if (!this.id) {
        this.editMode = true;
      }
    }
  },
  methods: {
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>